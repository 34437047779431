import { kazakhstanDefaultPaymentCards } from "app/constants/CurrencyConstants";

export const reducer: typeof window.kinosite.reducer = {
	cinema: {
		address: '3 мкр. Авангард д. 40а, ТЦ "Атаба"',
		place: null,
		phoneNumbers: [],
		facebook: null,
		instagram: null,
		vk: null,
		twitter: null,
		odnoklassniki: null,
		youtube: null,
		app: {
			appstore: null,
			googleplay: null,
		},
		payment: {
			active: true,
			card: kazakhstanDefaultPaymentCards,
		},
		maps: null,
		selectButton: {
			options: [],
			selectedOptionValue: null,
		},
		soonPageIsEnabled: false,
	},
	feedback: true,
	hasNeedHideRefundButton: true
};
