import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '225, 8, 134',
		'primary-dark': '118, 7, 64',
		'accent': '242, 126, 0',
		'accent-plus': '255, 255, 255',
	},
});
